<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <a-row :gutter="8">
    <a-col flex="25%">
      <a-card>
        <a-cascader v-model:value="brands" :options="carCustome" :show-search="{ filter }" @change="queryVehicle" placeholder="查询/选择 车型" />
      </a-card>
    </a-col>
    <a-col flex="auto">
      <a-card>
        <a-tabs type="card" v-model:activeKey="fromKey" @change="changeBrandKey">
          <a-tab-pane key=1 tab="添加车辆品牌">
            <a-form ref="formRefBrand" :model="form" :rules="rules" :labelCol="{flex:'100px'}" :wrapperCol="{flex:'auto'}">
              <a-form-item label="车辆品牌" name="brand">
                <a-input v-model:value="form.brand" />
              </a-form-item>
              <a-form-item>
                <a-row>
                  <a-col :span="24" style="text-align: right;margin-top:20px">
                    <a-button type="primary" @click.prevent="save">添加车辆品牌</a-button>
                  </a-col>
                </a-row>
              </a-form-item>

            </a-form>

          </a-tab-pane>
          <a-tab-pane key="2" tab="添加车辆型号">
            <a-form ref="formRef" :model="form" :rules="rules" :labelCol="{flex:'100px'}" :wrapperCol="{flex:'auto'}">
              <a-form-item label="车辆品牌">
                {{(vehicleBrands[0].label) }}
              </a-form-item>
              <a-form-item label="车辆型号" name="model">
                <a-input v-model:value="form.model" />
              </a-form-item>
              <a-form-item>
                <a-row>
                  <a-col :span="24" style="text-align: right;margin-top:20px">
                    <a-button type="primary" @click="save()">添加车辆型号</a-button>
                  </a-col>
                </a-row>
              </a-form-item>
            </a-form>
          </a-tab-pane>
        </a-tabs>
      </a-card>
    </a-col>

  </a-row>

</template>
<script>
// import { brand as brandAll, brandAdd, modelAddem } from '@/api/system/car'
import { brandAdd, modelAdd } from '@/api/system/car'
import { onMounted, reactive, ref, toRefs } from 'vue'
import { message } from 'ant-design-vue'
import { carBrandTree } from '@/api/system/setting'
export default {
  components: {
  },
  setup () {
    const state = reactive({
      fromKey: ref('1'),
      brands: [],
      vehicleBrands: [],
      customers: [],
      carCustome: [],
      confirmLoading: false,
      form: {},
      formRefBrand: null,
      formRef: null,
      rules: {
        brand: [{ required: true, message: '请填写车辆品牌', trigger: 'blur' }],
        model: [{ required: true, message: '请填写车辆型号', trigger: 'blur' }]
      }
    })

    const loadData = () => {
      state.loadingMore = true
      carBrandTree({}).then((res) => {
        // console.log(res)  //所有车型的数据
        state.carCustome = res.data
      })

      state.loadingMore = false
    }

    onMounted(loadData)

    const save = () => {
      if (state.fromKey === '1') {
        state.formRefBrand
          .validate()
          .then(() => {
            state.confirmLoading = true

            brandAdd({ brandName: state.form.brand }).then(res => {
              console.log(res)
              if (res.code === 10000) {
                message.success(res.msg)
                state.form.bran = ''
                loadData()
              }
            }).catch(err => {
              console.log(err)
            })
            state.confirmLoading = false
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        state.formRef
          .validate()
          .then(() => {
            state.confirmLoading = true
            modelAdd({ brandId: state.brands[0], modelName: state.form.model }).then(res => {
              console.log(res)
              if (res.code === 10000) {
                message.success(res.msg)
                state.form.model = ''
                loadData()
              }
            }).catch(err => {
              console.log(err)
            })
            state.confirmLoading = false
          })
          .catch(error => {
            console.log(error)
          })
      }
    }
    const changeBrandKey = (v) => {
      if (v === '2' && state.brands.length === 0) {
        state.fromKey = '1'
        message.error('请选择车辆品牌')
      }
    }
    const filter = (inputValue, path) => {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    }
    const queryVehicle = (value, choice) => {
      state.vehicleBrands = choice
    }
    return {
      ...toRefs(state),
      queryVehicle,
      filter,
      changeBrandKey,
      save
    }
  }
}
</script>
