import { getRequest, postBodyRequest } from '@/utils/axios'

// 所有品牌
export function car (params) {
  return getRequest('/cis/system/car', params)
}
// 所有品牌
export function brand (params) {
  return getRequest('/cis/system/car/brand', params)
}
// 新增品牌
export function brandAdd (params) {
  return postBodyRequest('/cis/system/car/brand/add', params)
}
// 新增车型
export function modelAdd (params) {
  return postBodyRequest('/cis/system/car/model/add', params)
}
